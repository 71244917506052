.wrapper {
  background: linear-gradient(
    92.47deg,
    rgba(24, 156, 222, 0.33) -39.01%,
    #eaffea -38.99%,
    #eaf6fe 50.05%,
    #e3e1fc 136.46%
  );
  min-height: 100vh;
  position: relative;
}

.mainContainer {
  max-width: 1440px !important;
  margin-inline: auto;
  position: relative;
}

.topBar {
  position: fixed;
  min-height: 88px;
  padding-inline: 50px;
  & > figure {
    display: flex;
    align-items: center;
    min-height: inherit;
    gap: 10px;
  }
  & img{
    height:32px;
  }
  & h3{
    font-family: 'avenir_medium';
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0px;
    letter-spacing: 4px;
  }
  & small {
    text-transform: uppercase;
    font-size: 10px;
    color: rgb(82, 82, 82)
  }
}

.mainDetailsContainer {
  min-height: calc(100vh - 88px);
  display: flex;
  justify-content: space-between;
  & > section {
    width: 100%;
  }
}

.rightSection {
  display: flex;
  justify-content: center;
  z-index: 2;
  margin-top: 3rem;
}

.leftSection {
  & > div {
    position: fixed;
    top: 88px;
    & > h4 {
      position: absolute;
      padding: 0rem;
      margin-left: 100px;
      width: 440px;
      top: clamp(10px, 7.72vh, 140px);
      left: 0;
      font: 800 clamp(20px, 2.22vw, 32px) / clamp(20px, 3.33vw, 48px)
          "avenir_regular",
        "sans-serif";
      & > span {
        font-weight: 400 !important;
        color: #189cde;
      }
    }
    & > img {
      position: fixed;
      width: clamp(350px, 40.402vw, 913px);
      bottom: 0rem;
      left: 0rem;
      object-fit: cover;
    }
  }
}

.credit {
  position: absolute;
  font: 400 12px / 18px "avenir_regular", "sans-serif";
  color: #5a5a5a;
  text-align: right;
  padding: 10px 12px;
  bottom: 0;
  right: 0;
}

.driftSection {
  position: fixed;
  bottom: 8rem;
  left: 0rem;
  width: clamp(500px, 90%, 965.25px);
  height: clamp(400px, 90%, 878.6px);
  object-fit: cover;
  opacity: 0.4;
}

.imgWrapper {
  position: fixed;
  overflow: hidden;
}

.imgWrapper > img {
  animation-name: fade;
  animation-iteration-count: infinite;
  animation-duration: 40s;
}


.bgFade2 {
  animation-delay: -20s;
}

.textWrapper {
  position: relative;
  & > h4 {
    position: absolute;
    animation-name: fadeText;
    animation-iteration-count: infinite;
    animation-duration: 40s;
  }
}

.nametag:nth-child(2){
  animation-delay: -20s;
}


@keyframes fade {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  53% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes fadeText {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }
  53% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}