.container{
  height:100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.mainContainer{
  height:100%;
   overflow: scroll;
   padding: 10px;
}