/* FONTS */
@font-face {
  font-family: 'regular_regular';
  src: url('./fonts/Regular/RegularRegular.otf')  format("opentype");
}

@font-face {
  font-family: 'regular_medium';
  src: url('./fonts/Regular/RegularMedium.otf')  format("opentype");
}

@font-face {
  font-family: 'regular_semibold';
  src: url('./fonts/Regular/RegularSemibold.otf')  format("opentype");
}

@font-face {
  font-family: 'regular_heavy';
  src: url('./fonts/Regular/RegularHeavy.otf')  format("opentype");
}

@font-face {
  font-family: "avenir_roman";
  src: url("./fonts/Avenir/AvenirRoman.otf") format("opentype");
}

@font-face {
  font-family: "avenir_regular";
  src: url("./fonts/Avenir/AvenirRegular.otf") format("opentype");
}

@font-face {
  font-family: "avenir_medium";
  src: url("./fonts/Avenir/AvenirMedium.otf") format("opentype");
}

@font-face {
  font-family: "avenir_heavy";
  src: url("./fonts/Avenir/AvenirHeavy.otf") format("opentype");
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: regular_medium;
}
h1, h2, h3, h4, h5, h6, .ant-card-head-title {
  font-family: regular_semibold;
}
body {
  background-color: rgb(241, 243, 246) !important;
  font-size: 16px;
  font-family: regular_regular;
}

.flex {
  display:flex;
}
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
.ant-card {
  background: transparent !important;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: 1px solid transparent;
}
.chat-modal, .attach-files-modal {
  width: 90% !important;
  max-width: 1000px !important;
  max-height: 90vh;
  overflow: auto;
  top: 40px !important;
  padding: 0 !important;
}
.chat-modal .conversation-header {
  padding-right: 20px;
}
.chat-modal .chat-message-list {
  flex-direction: column;
} 
.chat-modal .chat-message-list .support-msg, .chat-modal .chat-message-list .client-msg {
  flex-direction: column;
  margin: 5px 0;
}
.chat-modal .chat-message-list .support-msg p, .chat-modal .chat-message-list .client-msg p {
  margin: 0 10px;
  padding: 10px 15px;
  border-radius: 5px;
  max-width: 1000px;
}
.chat-modal .chat-message-list .support-msg p {
  background-color: #1890ff21;
  border-radius: 25px;
  margin: 3px 0;
}
.chat-modal .chat-message-list .support-msg p:first-child {
  background-color: #1890ff21;
  border-radius: 25px 25px 2px 25px;
  margin: 3px 0;
}
.chat-modal .chat-message-list .support-msg p:last-child {
  background-color: #1890ff21;
  border-radius: 25px 2px 25px 25px;
  margin: 3px 0;
}
.chat-modal .chat-message-list .client-msg p {
  background-color: #43b1a921;
  border-radius: 25px;
  margin: 3px 0;
}
.chat-modal .chat-message-list .client-msg p:first-child {
  background-color: #43b1a921;
  border-radius: 25px 25px 25px 2px;
  margin: 3px 0;
}
.chat-modal .chat-message-list .client-msg p:last-child {
  background-color: #43b1a921;
  border-radius: 2px 25px 25px 25px;
  margin: 3px 0;
}
.chat-modal .chat-message-list .support-msg-body .initials, .chat-modal .chat-message-list .client-msg-body .initials {
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 50%;
  padding-top: 8px;
}
.chat-modal .chat-message-list .support-msg-body .initials {
  background-color: #1890ff;
  margin: 10px 0px 0px 5px;
}
.chat-modal .chat-message-list .client-msg-body .initials {
  background-color: #43b1a9;
  margin: 10px 5px 0px 0px;
}
.chat-modal .chat-message-list .support-msg-body {
  align-self: flex-end;
  flex-direction: row-reverse;
}
.chat-modal .chat-message-list .client-msg-body {
  align-self: flex-start;
}
.chat-modal .chat-message-list .support-msg {
  align-items: flex-end;
}
.chat-modal .chat-message-list .client-msg {
  align-items: flex-start;
}
.chat-modal .ant-modal-footer {
  display: flex;
  align-items: center;
  position: sticky;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 2;
  box-shadow: 0 0 15px -2px #ccc;
}
.chat-modal .send-icon {
  margin-left: 10px;
  cursor: pointer;
}
.chat-modal .chat-message-form {
  width: 100%;
  align-items: center;
}
.chat-modal .chat-message-form input {
  flex-grow: 1;
  margin-right: 5px;
  outline: none;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 0 2px transparent;
  transition: all 0.2s linear;
}
.chat-modal .chat-message-form input:hover {
  border-color: #1890ff;
}
.chat-modal .chat-message-form input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px #1890ff33;
}
.chat-modal .chat-message-form button {
  padding: 0;
  margin: 0;
  width: 30px;
  height: 24px;
  border: none;
  background: transparent;
  outline: none;
}
.attach-files-modal .report-list-header {
  color: #000;
  font-size: 18px;
}
.attach-files-modal .report-list-item {
  width: 100%;
}
.attach-files-modal .report-name, .attach-files-modal .report-type {
  margin: 0;
  width: 50%;
}

::-webkit-scrollbar {
  width: 7px !important;
  height: 7px !important;
  background-color: #fff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2979ff80;
  border: 1px solid #fff;
}
::-webkit-scrollbar-thumb:hover {
  background: #2979ff;
}
::-webkit-scrollbar-thumb:active {
  background-color: #2979ff;
}

.manual-required .ant-form-item-required::before {
   content: "*";
  color: red;
}

.custom-switch {
  width: 100px; /* Adjust the width as needed */
}

.custom-switch2{
  width: 120px; /* Adjust the width as needed */
}

.custom-switch .ant-switch-inner {
  width: auto; /* Ensure the inner text adjusts to the new width */
 
}