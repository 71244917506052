.container{
  height:100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.mainContainer{
  height:100vh;
  overflow-y: scroll;
  padding: 10px 15px;
}

.inputBox{
  & div{
    display: flex;
    justify-content: center;
    align-items: center;
    padding:20px;
  }
}


.uploadButton{
    outline:none;
    border:1px solid #002F75;
    background-color: #002F75;
    color: #ffffff;
    font-family: 'avenir_regular';
    font-size: 18px;
    display: block;
    padding: 10px 20px;
    border-radius: 6px;
    &:hover{
      cursor: pointer;
    }
}



