.inputContainer {
  position: relative;
  & > input {
    height: 46px;
    width: 100%;
    padding: 12px;
    font: 400 16px "Avenir Roman", "sans-serif";
  }
  & > input::placeholder {
    color: #878787;
    font: 400 16px  "Avenir Roman", "sans-serif";
  }
  & > input:focus {
    outline-color: #2d2d2d;
  }
  & > input[type="password"] {
    font-size: larger;
  }

  & > button {
    position: absolute;
    right: 10px;
    top: 12px;
    background-color: none;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    & > img {
      width: 24px;
      height: 24px;
    }
  }

  & > button:active {
    transform: translateY(1px);
  }
}
