*,
*::before,
*::after {
  box-sizing: border-box;
}

html{
  font-size: 18px;
  height: 100%;
}

body {
  font-family: "avenir_regular";
  font-size: 1rem;
  height: 100%;
  
}

.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;
}


.disabled{
  filter: grayscale(.5);
}

.disabled:hover{
  cursor:not-allowed;
}

#root {
  /* height: 100%; */
}
.flex{
  display: flex;
}