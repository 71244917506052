.container{
  height:100%;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  
}

.mainContainer{
  height:100%;
   /* overflow: scroll; */
   padding: 15px;
}