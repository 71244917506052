.custom-table .ant-table-thead > tr > th {
    align-items: center;
    font-size: 15px;
    background: #C8E5F9;
    color: black;
    vertical-align: middle;
    font-weight: 600;
  }
  
  .custom-table .ant-table-thead > tr > td{
    font-weight: 400;
    font-size: 0.875rem;
  }

  .ant-form-item-label > label {
    font-size: 0.875rem !important;
    font-weight: 400 !important;
  }