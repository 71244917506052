.container{
  height:100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.mainContainer{
  height:100%;
  overflow: scroll;
  padding: 10px;
}

.userDetails{
  margin: 20px auto;
  & h4{
    color :#002F75;
    font-family: 'avenir_medium';
    font-size: 24px;
    margin: 15px auto;
  };
  & p:nth-child(even){
    font-size: 18px;
    color: blue;
    margin-bottom: 20px;
    text-decoration: underline
  };
  & p:nth-child(odd){
    font-size: 18px;
    color: rgb(75, 75, 75);
    margin-bottom: 20px;
    
  };
}

.changePassword{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: space-between;
   & h4{
    color :#002F75;
    font-family: 'avenir_medium';
    font-size: 24px;
    text-align: left;
    display: inline-block;
    margin-bottom: 15px;
  };
  & small{
    color: red;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: 'avenir_regular';
  }
   & input{
    margin-bottom: 10px;
    height:34px;
    outline: none;
    border: 1px solid #002F75;
    border-radius: 4px;
    width: 300px;
    padding: 10px;
   };
}

.enablebutton{
  outline:none;
  border:1px solid #002F75;
  background-color: #002F75;
  color: #ffffff;
  font-family: 'avenir_regular';
  font-size: 16px;
  display: block;
  width: 300px;
  padding: 8px;
  border-radius: 6px;
  &:hover{
    cursor: pointer;
  }
 }

 .disablebutton{
  outline:none;
  border:1px solid gray;
  background-color: gray;
  color: rgb(59, 59, 59);
  font-family: 'avenir_regular';
  font-size: 16px;
  display: block;
  width: 300px;
  padding: 8px;
  border-radius: 6px;
  &:hover{
    cursor: not-allowed;
  }
 }