.mainInputContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  & > label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    font: 800 14px / 21px "avenir_regular", "sans-serif";
    color: #2d2d2d;
  }
  & > input {
    height: 46px;
    width: 100%;
    padding: 12px;
  }
  & > input::placeholder {
    color: #878787;
    font: 400 16px / 25.6px "avenir_regular", "sans-serif";
  }
  & > input:focus {
    outline-color: #2d2d2d;
  }
  & > input[type="password"] {
    font-size: larger;
  }
}

.errorInput {
  font: 400 clamp(12px, 1.11vw, 16px) / 25.6px "avenir_regular";
  color: #cf0a0a;
}

.inputLabel {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  font: 800 14px / 21px "avenir_regular", "sans-serif";
  color: #2d2d2d;
}

.inputField {
  height: 46px;
  width: 100%;
  padding: 12px;
  
  & > input::placeholder {
    color: #878787;
    font: 400 16px / 25.6px "avenir_regular", "sans-serif";
  }
  & > input:focus {
    outline-color: #2d2d2d;
  }
  & > input[type="password"] {
    font-size: larger;
  }
}
