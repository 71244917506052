.navBarContainerLarge{
  height:100%;
  flex:0 1 18%;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: relative;
  margin-left: 6px;
}

.navBarContainerSmall{
  height:100%;
  flex:0 1 4%;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  position: relative;
  margin-left: 6px;
}

.activeNav{
  text-decoration: none;
  padding: 8px 6px;
  border-radius: 8px;
  background-color: #D1D5DB ;
  color:#002F75 ;
  width: 90%;
  font-size: .9rem;
  font-family: 'avenir_medium';
  display: block;
  margin-top: 5px;
}

.nav{
  color:black;
  text-decoration: none;
  padding: 8px 6px;
  border-radius: 8px;
  display: block;
  background-color: #fff;
  color: #002F75;
  width: 90%;
  font-size: .9rem;
  font-family: 'avenir_medium';
  margin-top: 5px;
  height: 90%;
  &:hover{
    background-color: rgba(209, 213, 219, .5);
  }

}

.navButton{
  color:black;
  text-decoration: none;
  border:none;
  outline: none;
  padding: 8px;
  border-radius: 8px;
  display: block;
  background-color: #fff;
  color: #002F75;
  width: 90%;
  font-size: .9rem;
  font-family: 'avenir_medium';
  margin-top: 5px;

  text-align: start;
   &:hover{
     cursor: pointer;
     background-color: rgba(209, 213, 219, .5);
   }
}

nav ul li img{
  width: 22px;
  height: 22px;
  vertical-align: middle;
}
nav ul li span{
  vertical-align:middle;
  margin-left: 10px;
}

.navBarContainerSmall nav ul .nav, 
.navBarContainerSmall nav ul .activeNav,
.navBarContainerSmall nav ul .navButton{
   display: flex;
   justify-content: center;
   align-items: center;
   padding-right: 10px;
}  

.toggle{
  position: absolute;
  top: 20px;
  right:-15px;
   border-radius: 50%;
   background-color: #D1D5DB;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-left: 5px;
   width: 30px;
  height: 30px;
  &:hover{
    cursor: pointer;
  };
   & img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    vertical-align: middle;
   };
}


.logoContainerLarge{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  padding: 18px 20px;
  margin-left: -10px;
  border-bottom: 1px solid rgba(0, 47, 117, .5);
  margin-bottom: 20px;
  
  & img{
    margin-right: 10px;
    width:32px;
    height:32px;
  }

  & h1{
    color:#000;
    font-size: 20px;
    color: #002F75;
    font-family: 'avenir_medium';
  }
  & small{
     font-size: 9px;
     color: rgba(0, 47, 117, .5);
     font-family: 'avenir_regular';
  }
}


.logoContainerSmall{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  padding:22px  20px;
  margin-left: -10px;
  border-bottom: 1px solid rgba(0, 47, 117, .5);
  margin-bottom: 20px;
  & img{
    width:32px;
    height:32px;
  }


}