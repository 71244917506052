.custom-table-style .ant-table-thead > tr > th {
    color: white;
    background-color: #67bfff;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
  }

.custom-table-style .ant-table-tbody > tr > td {
padding: 15px; /* Set padding for table cells */
border-bottom: 1px solid lightgray; /* Border bottom for cells */
}

/* Alternating row background colors */
.custom-table-style .ant-table-tbody > tr:nth-child(even) > td {
background-color: #f0f7fc; /* Light blue for odd rows */
}

.custom-table-style .ant-table-tbody > tr:nth-child(even):hover > td {
    background-color: #f0f7fc !important; /* Maintain light blue on hover for odd rows */
  }

.custom-table-style .ant-table-tbody > tr > td > button{
    background-color: white;
}

.custom-table-style .ant-table-tbody > tr:hover > td {
    background-color: inherit !important; /* Inherit the existing background color */
  }